import 'whatwg-fetch';
import Vue from 'vue';
import App from '@/App.vue';
import router from '@/router/index';
import { vueStore } from '@/store/index';
import 'formdata-polyfill';

const favicon = document.createElement('link');
favicon.rel = 'icon';

if (window.location.host.includes('.ru')) {
  document.title = 'РЦИС ID';
  favicon.href = `${process.env.VUE_APP_BASE}favicon-ru.ico`;
} else {
  document.title = 'IPChainID';
  favicon.href = `${process.env.VUE_APP_BASE}favicon-eu.ico`;
}

document.head.appendChild(favicon);

Vue.config.productionTip = false;

new Vue({
  router,
  store: vueStore,
  render: (h) => h(App),
}).$mount('#app');
