






































/* eslint-disable @typescript-eslint/camelcase */
import { BaseComponent, Component } from '@/components/BaseComponent';
import { Watch } from 'vue-property-decorator';
import Spinner from '@/components/Spinner.vue';

@Component({
  components: {
    Spinner,
  },
})
export default class Layout extends BaseComponent {
  get isRuHost() {
    return this.startData?.default_lang === 'ru';
  }

  get startData() {
    return this.session.getStartData();
  }

  get clientId() {
    return this.authorization.getClientId();
  }

  logo: string | null = null;

  background: string | null = null;

  // eslint-disable-next-line class-methods-use-this
  private tryGetImage(source: string): Promise<string | null> {
    return new Promise((resolve) => {
      const image = new Image();
      image.src = source;
      image.onload = () => {
        resolve(source);
      };
      image.onerror = () => {
        resolve(null);
      };
    });
  }

  @Watch('clientId', { immediate: true })
  onChangeClientId(value: string | undefined) {
    if (value) {
      const payload = { client_id: value };
      const logoLink = this.pds.getLogoLink(payload);
      const backgroundLink = this.pds.getBackgroundLink(payload);
      this.tryGetImage(logoLink).then((source) => {
        this.logo = source;
      });
      this.tryGetImage(backgroundLink).then((source) => {
        this.background = source;
      });
    }
  }
}
