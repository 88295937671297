/* eslint-disable max-classes-per-file */
import { ErrorResponse, HttpClient as IHttpClient, ResponseType } from '@/types';
import Result from '@/helpers/result';

async function createResponseError(response: globalThis.Response): Promise<ErrorResponse> {
  const { status, statusText } = response;
  if (status === 400) {
    const error: ErrorResponse = await response.json();
    return error;
  }
  return {
    code: `http.${status}`,
    messages: [statusText],
  };
}

export default class HttpClient implements IHttpClient {
  private headers: Record<string, string> = {}

  // eslint-disable-next-line no-useless-constructor
  constructor(
    public base: string = '/',
    public sessionHeaders: string[] = ['X-CSRF-Token'],
  ) {}

  getHeader(name: string) {
    const header = this.headers[name];
    if (header) return header;
    return null;
  }

  setHeader(name: string, value: string) {
    this.headers[name] = value;
  }

  private setHeaders(response: globalThis.Response) {
    this.sessionHeaders.forEach((name) => {
      const value = response.headers.get(name.toLowerCase());
      if (value) {
        this.headers[name] = value;
      }
    });
  }

  async get<ResponseBodyType>(url: string, params?: URLSearchParams, responseType: ResponseType = 'json') {
    const response = await fetch(this.buildUrl(url, params));
    this.setHeaders(response);
    if (response.status !== 200) {
      return Result.fail(await createResponseError(response));
    }
    let content;
    switch (responseType) {
      case 'json':
        content = await response.json();
        break;
      case 'blob':
        content = await response.blob();
        break;
      default:
        content = await response.text();
    }
    return Result.ok(content as ResponseBodyType);
  }

  async getBlob(url: string, params?: URLSearchParams) {
    return this.get<Blob>(url, params, 'blob');
  }

  async getText(url: string, params?: URLSearchParams) {
    return this.get<string>(url, params, 'text');
  }

  async post<ResponseBodyType>(url: string, body: URLSearchParams) {
    const response = await fetch(this.buildUrl(url), {
      headers: this.headers,
      method: 'post',
      body,
      credentials: 'include',
    });
    this.setHeaders(response);
    if (response.status === 200) {
      const content = await response.json() as ResponseBodyType;
      return Result.ok(content);
    }
    return Result.fail(await createResponseError(response));
  }

  async postNoContent(url: string, body: URLSearchParams) {
    const response = await fetch(this.buildUrl(url), {
      headers: this.headers,
      method: 'post',
      body,
      credentials: 'include',
    });
    this.setHeaders(response);
    if (response.status === 204) {
      return Result.ok(null);
    }
    return Result.fail(await createResponseError(response));
  }

  buildUrl(url: string, params?: URLSearchParams) {
    const normalize = (path: string) => path.split('/').filter(Boolean).join('/');
    const base = new URL(normalize(this.base), window.location.origin).toString();
    const path = normalize(url);
    const search = params ? `?${params}` : '';
    return `${base}/${path}${search}`;
  }
}
