import { Messages } from '@/types';

const messages: Messages = {
  login: 'Кіру',
  incorrectIdentifierOrPassword: 'Сәйкестендіргіш немесе құпиясөз дұрыс емес',
  emailPhoneNumber: 'Email, телефон нөмірі...',
  email: 'Email',
  password: 'Құпиясөз',
  enterYourEmail: 'Өз еmail енгізіңіз',
  enterYourPassword: 'Өз құпиясөзіңізді енгізіңіз',
  enterYourEmailPhoneNumber: 'Өз email, телефон нөміріңізді енгізіңіз...',
  next: 'Әрі қарай',
  resetPassword: 'Құпиясөзді ауыстыру',
  resetLinkSending: 'Құпиясөзді ауыстыру үшін сілтемені жіберу',
  newPasswordCreation: 'Жаңа құпиясөзді жасау',
  newPasswordCreated: 'Жаңа құпиясөз жасалды',
  expiredLink: 'Ескірген сілтеме',
  authorization: 'Авторландыру',
  communications: 'Байланыс тәсілдері',
  identifiers: 'Сәйкестендіргіштер',
  names: 'Бүркеншік аттар',
  confirm: 'Растау',
  error: 'Қате',
  logout: 'Шығу',
  or: 'немесе',
  changeAccount: 'Аккаунтты ауыстыру',
  newPasswordSetSuccessfully: 'Жаңа құпиясөз сәтті орнатылды',
  sendPasswordResetLink: 'Құпиясөзді ауыстыру үшін сілтеме жіберу',
  weSentPasswordResetInformationTo: 'Құпиясөзді ауыстыру ақпараты {0} жіберілді',
  return: 'Қайту',
  passwordMustBeAtLeastCharactersInLength: 'Құпиясөз {0} таңбадан кем болмауы керек',
  reset: 'Ауыстыру',
  rememberMe: 'Мені есте сақтау',
  yourPasswordResetLinkHasExpired: 'Құпиясөзді өзгерту сілтемесі аяқталды',
  registration: 'Тіркеу',
  atLeastOneWithTypeIsRequired: '«{1}» түріндегі ең болмаса бір {0} міндетті',
  communication: 'байланыс тәсілі',
  identifier: 'сәйкестендіргіш',
  name: 'бүркеншік ат',
  selectIdentifier: 'Кодты жіберу үшін сәйкестендіргішті таңдаңыз',
  code: 'Код',
  enterCode: 'Өз кодыңызды енгізіңіз',
  sendingNewCodeIsPossibleAfter: 'Жаңа кодты {0} арқылы жіберуге болады',
  send: 'жіберу',
  incorrectCode: 'Код дұрыс емес',
  tooManyAttemptsCodeMustBeResent: 'Әрекеттер тым көп, кодты қайта жіберу қажет',
  emailOrPhone: 'Email немесе телефон нөмірі (7xxxxxxxxx)',
  enterEmailOrPhone: 'Өз email немесе телефон нөміріңізді енгізіңіз (7xxxxxxxxx)',
  twoFactorAuthentication: 'Екі факторлы аутентификаттау',
  autologin: 'Автокіру',
  youInstructToSendInformationTo: 'Сіз келесі ақпаратты «{0}» жіберуге тапсырма бересіз',
  loginWith: 'ESIA арқылы кіру',
  pageNotFound: 'Бет табылмады',
  loading: 'Жүктеу',
  passwordMismatch: 'Passwords don\'t match',
};

export default messages;
