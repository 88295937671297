import { Messages } from '@/types';

const messages: Messages = {
  login: 'Մուտք',
  incorrectIdentifierOrPassword: 'Սխալ նույնականացում կամ գաղտնաբառ',
  emailPhoneNumber: 'Էլ․հասցի, հեռախոսահամար...',
  email: 'էլեկտրոնային հասցե',
  password: 'Գաղտնաբառ',
  enterYourEmail: 'Մուտքագրեք ձեր էլ․հասցեն',
  enterYourPassword: 'Մուտքագրեք ձեր գաղտնաբառը',
  enterYourEmailPhoneNumber: 'Մուտքագրեք ձեր էլ․հասցեն, հեռախոսահամարը․․',
  next: 'Հաջորդ',
  resetPassword: 'Գաղտնաբառի վերականգնում',
  resetLinkSending: 'Հղման ուղարկում  գաղտանաբառը վերականգնելու համար',
  newPasswordCreation: 'Նոր գաղտնաբառի ստեղծում',
  newPasswordCreated: 'Նոր գաղտնաբառը ստեղծված է',
  expiredLink: 'Հին հղում',
  authorization: 'Ավտորիզացիա /թուլտվություն/',
  communications: 'Կապի մեթոդներ',
  identifiers: 'Նույնացուցիչներ',
  names: 'Կեղծանուն',
  confirm: 'Հաստատել',
  error: 'Սխալ',
  logout: 'Դուրս գալ',
  or: 'Կամ',
  changeAccount: 'Օգտահաշվի փոփոխություն',
  newPasswordSetSuccessfully: 'Նոր գաղտնաբառը հաջողությամբ ստեղծված է',
  sendPasswordResetLink: 'Ուղարկել հղումը գաղտնաբառը փոփոխելու համար',
  weSentPasswordResetInformationTo: 'Գաղտնաբառի փոփոխության մասին տեղեկությոնները ուղարկվել են {0}',
  return: 'Վերադառնալ',
  passwordMustBeAtLeastCharactersInLength: 'Գաղտնաբառը պետք է ունենա առնվազն {0} նիշ',
  reset: 'Փոխել',
  rememberMe: 'Հիշել ծածկագիրը',
  yourPasswordResetLinkHasExpired: 'Ձեր գաղտնաբառի փոփոխման հղումը սպառվել է',
  registration: 'Գրանցում',
  atLeastOneWithTypeIsRequired: 'Նվազագույնը մեկը {0} տեսակի  "{1}" պարտադիր է',
  communication: 'կապի միջոց',
  identifier: 'Նույնացուցիչ',
  name: 'Կեղծանուն',
  selectIdentifier: 'Ընտրեք նույնականացում ծածկագրի ուղարկման համար',
  code: 'ծածկագիր',
  enterCode: 'Մուտքագրեք ձեր ծածկագիրը',
  sendingNewCodeIsPossibleAfter: 'Նոր ծածկագրի ուղարկումը հնարավոր է {0}',
  send: 'ուղարկել',
  incorrectCode: 'Սխալ ծածկագիր',
  tooManyAttemptsCodeMustBeResent: 'Չափազանց շատ փորձեր, նորից ուղարկեք ծածկագիրը',
  emailOrPhone: 'Email կամ հեռախոսահամար (374xxxxxxxxxx)',
  enterEmailOrPhone: 'Մուտքագրեք ձեր Email կամ հեռախոսահամարը (374xxxxxxxxxx)',
  twoFactorAuthentication: 'երկփուլ նույնականացում',
  autologin: 'Ավտոմուտք',
  youInstructToSendInformationTo: 'Դուք հրահանգում եք ուղարկել հետևյալ տեղեկությունները «{0}»-ին',
  loginWith: 'Մուտք ESIA օգնությամբ',
  pageNotFound: 'Էջը չի հայտնաբերվել',
  loading: 'Բեռնում',
  passwordMismatch: 'Passwords don\'t match',
};

export default messages;
