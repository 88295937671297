/* eslint-disable max-len */
import { NavigationGuard } from 'vue-router';

export * from '@/types/i18n';

export enum Pages {
  Authorize = 'authorize_page',
  Login = 'login_page',
  AutoLogin = 'auto_login_page',
  Allow = 'allow_page',
  TwoFactorAuthentication = '2fa_page',
  SendResetPasswordCode = 'send_rpc_page',
  UseResetPasswordCode = 'use_rpc_page',
  Error = 'error_page',
  Redirect = 'redirect:{url}',
  NotFound = 'not_found_page',
  Callback = 'callback',
}

export interface ApiResponse<PageType, PayloadType> {
  page_to_show: PageType;
  payload: PayloadType;
}

export type Redirect = ApiResponse<Pages.Redirect, undefined>;

export interface AuthorizationData {
  extauth1_is_enable: boolean;
  client_name: string;
  client_logo?: string;
  client_back?: string;
  client_login_text: string;
  lang: string;
  client_reg_uri?: string;
  client_back_uri?: string;
}

export interface AuthorizeAutoLoginData extends AuthorizationData, AutoLoginData {}

export interface StartResponse {
  default_lang: string;
  chain_site_uri: string;
  extauth1_is_enable: boolean;
  extauth2_is_enable: boolean;
  extauth2_redirect_uri?: string;
  extauth2_client_id?: string;
  extauth2_state?: string;
}

export type AuthorizeResponse =
  | ApiResponse<Pages.Login, AuthorizationData>
  | ApiResponse<Pages.AutoLogin, AuthorizeAutoLoginData>;

export interface CommunicationCheck {
  check: boolean;
  index: number;
  communication: string;
  type: string;
}

export interface IdentifierCheck {
  check: boolean;
  index: number;
  identifier: string;
  type: string;
}

export interface NameCheck {
  check: boolean;
  index: number;
  first_name: string;
  last_name?: string;
  middle_name?: string;
  type: string;
}

export interface AllowData {
  optional_communication_checks?: CommunicationCheck[];
  optional_identifier_checks?: IdentifierCheck[];
  optional_name_checks?: NameCheck[];
  force_communication_checks?: {
    [type: string]: CommunicationCheck[];
  };
  force_identifier_checks?: {
    [type: string]: IdentifierCheck[];
  };
  force_name_checks?: {
    [type: string]: NameCheck[];
  };
}

export interface TwoFactorAuthenticationData {
  identifiers_2fa: string[];
  resend_sec_2fa: number;
}

export type LoginResponse = ApiResponse<Pages.Allow, AllowData> | ApiResponse<Pages.TwoFactorAuthentication, TwoFactorAuthenticationData> | Redirect;

export interface IdentifierRequestPayload {
  identifier: string;
}

export interface CodeRequestPayload {
  code: string;
}

export interface PasswordRequestPayload {
  password: string;
  confirm_password?: string;
}

export interface LoginRequestPayload extends IdentifierRequestPayload, PasswordRequestPayload {
  remember: boolean;
}

export interface SendRpcRequestPayload extends IdentifierRequestPayload {
  is_authorized: boolean;
  lang?: string;
  client_id?: string;
}

export interface UseRpcRequestPayload extends CodeRequestPayload, PasswordRequestPayload {
  lang: string;
}

export interface AllowRequestPayload {
  identifier: number[];
  name: number[];
  communication: number[];
}

export interface ClientIdRequestPayload {
  client_id: string;
}

export interface TemplateRequestPayload extends ClientIdRequestPayload {
  type: string;
  lang: string;
}

export interface UseResetPasswordCodeQuery extends ClientIdRequestPayload, CodeRequestPayload {
  lang: string;
}

export interface AutoLoginData {
  autologin_identifiers: string[];
}

export type LogoutResponse = ApiResponse<Pages.AutoLogin | Pages.Login, AutoLoginData>;

export type Use2FaResponse = ApiResponse<Pages.Allow, AllowData> | Redirect;

export interface SuccessResult<T> {
  readonly value: T;
  isSuccess(): this is SuccessResult<T>;
  isFailure(): false;
}

export interface FailResult<E> {
  readonly error: E;
  isSuccess(): false;
  isFailure(): this is FailResult<E>;
}

export type ResultOrError<T, E> = SuccessResult<T> | FailResult<E>;

export type ResponseOrError<T> = Promise<ResultOrError<T, ErrorResponse>>;

export interface PdsApi {
  authorize(payload: URLSearchParams): ResponseOrError<AuthorizeResponse>;
  login(payload: LoginRequestPayload): ResponseOrError<LoginResponse>;
  autoLogin(payload: IdentifierRequestPayload): ResponseOrError<LoginResponse>;
  logout(payload: IdentifierRequestPayload): ResponseOrError<LogoutResponse>;
  allow(payload: AllowRequestPayload): ResponseOrError<Redirect>;
  sendTwoFactorAuthenticationCode(payload: IdentifierRequestPayload): ResponseOrError<null>;
  useTwoFactorAuthenticationCode(payload: CodeRequestPayload): ResponseOrError<Use2FaResponse>;
  sendResetPasswordCode(payload: SendRpcRequestPayload): ResponseOrError<null>;
  useResetPasswordCode(payload: UseRpcRequestPayload): ResponseOrError<null>;
  getLogoLink(payload: ClientIdRequestPayload): string;
  getBackgroundLink(payload: ClientIdRequestPayload): string;
  template(payload: TemplateRequestPayload): ResponseOrError<string>;
  start(clientId?: string | null): ResponseOrError<StartResponse>;
  getLoginWithLink(): ResponseOrError<Redirect>;
  callback(payload: URLSearchParams): ResponseOrError<LoginResponse>;
  getHttpClient(): HttpClient;
}

export interface ErrorResponse {
  /** @deprecated */
  title?: string;
  code?: string;
  messages: string[];
}

export type PageTransition = AuthorizeResponse | LoginResponse | LogoutResponse | Redirect | Use2FaResponse;

export type ResponseType = 'json' | 'blob' | 'text';

export interface HttpClient {
  get<ResponseBodyType>(
    url: string,
    params?: URLSearchParams,
    responseType?: ResponseType
  ): ResponseOrError<ResponseBodyType>;
  getBlob(url: string, params?: URLSearchParams): ResponseOrError<Blob>;
  getText(url: string, params?: URLSearchParams): ResponseOrError<string>;
  post<ResponseBodyType>(url: string, body: URLSearchParams): ResponseOrError<ResponseBodyType>;
  postNoContent(url: string, body: URLSearchParams): ResponseOrError<null>;
  buildUrl(url: string, params?: URLSearchParams): string;
  getHeader(name: string): string | null;
  setHeader(name: string, value: string): void;
}

export interface SavedPages {
  [Pages.AutoLogin]: AutoLoginData;
  [Pages.Error]: ErrorResponse;
  [Pages.TwoFactorAuthentication]: TwoFactorAuthenticationData;
  [Pages.Allow]: AllowData;
  [Pages.Login]: AuthorizationData | AutoLoginData;
}

export interface Templates {
  [key: string]: string | undefined | null;
}

export interface Storage<T> {
  get<K extends keyof T>(page: K): Partial<T>[K];

  save<K extends keyof T>(page: K, data: Partial<T>[K]): void;
}

export interface StoreState {
  clientId?: string;
  clientLang?: string;
  settings: AuthorizationData | null;
  lang: string;
  resetPasswordCode: string;
  savedPages: Partial<SavedPages>;
  templates: Templates;
}

export interface VueComponentGuards {
  beforeRouteEnter?: NavigationGuard;
  beforeRouteLeave?: NavigationGuard;
  beforeRouteUpdate?: NavigationGuard;
}

export type ApiResponseResult = ResultOrError<PageTransition, ErrorResponse>;

export interface Template {
  chainLink?: string;
  backLink?: string;
  error?: {
    title?: string;
    messages: {
      text: string;
    }[];
  };
}

export interface PageTemplateError extends Template {
  /** @deprecated */
  title?: string;
  messages: {
    text: string;
  }[];
}

export interface TemplateNameCheck {
  check: boolean;
  index: number;
  firstName: string;
  lastName?: string;
  middleName?: string;
  type: string;
}

export interface TemplateForceCheck {
  error: boolean;
  type: string;
}

export interface TemplateForceCommunicationCheck extends TemplateForceCheck {
  communications: CommunicationCheck[];
}

export interface TemplateForceIdentifierCheck extends TemplateForceCheck {
  identifiers: IdentifierCheck[];
}

export interface TemplateForceNameCheck extends TemplateForceCheck {
  names: TemplateNameCheck[];
}

export interface AllowPageTemplate extends Template {
  allowLink: string;
  communicationsNotEmpty: boolean;
  identifiersNotEmpty: boolean;
  namesNotEmpty: boolean;
  optionalCommunications: CommunicationCheck[];
  optionalIdentifiers: IdentifierCheck[];
  optionalNames: TemplateNameCheck[];
  forceCommunications: TemplateForceCommunicationCheck[];
  forceIdentifiers: TemplateForceIdentifierCheck[];
  forceNames: TemplateForceNameCheck[];
}

export interface AutoLoginPageTemplate extends Template {
  loginLink: string;
  identifiers: {
    identifier: string;
    autoLoginLink: string;
    logoutLink: string;
  }[];
}

export interface LoginPageTemplate extends Template {
  loginWithLink?: string;
  registrationLink?: string;
  resetPasswordLink: string;
  loginLink: string;
  showPasswordLink: string;
  passwordIsHidden: boolean;
  loginWith2ContainerId?: string;
}

export interface SendResetPasswordCodePageTemplate extends Template {
  successfullySent: boolean;
  resetPasswordLink: string;
  clientIsAuthorized: boolean;
  loginLink: string;
  identifier: string;
}

export interface UseResetPasswordCodePageTemplate extends Template {
  successfullySet: boolean;
  resetPasswordLink: string;
  showPasswordLink: string;
  passwordIsHidden: boolean;
  confirmPasswordIsHidden: boolean;
  showConfirmPasswordLink: string;
}

export interface TwoFactorAuthenticationPageTemplate extends Template {
  timerExpired: boolean;
  time: string;
  twoFactorAuthCodeLink: string;
  identifiers: {
    identifier: string;
    identifierLink: string;
  }[];
}

export interface PageTemplatesData {
  [Pages.Error]: Template;
  [Pages.Login]: LoginPageTemplate;
  [Pages.AutoLogin]: AutoLoginPageTemplate;
  [Pages.TwoFactorAuthentication]: TwoFactorAuthenticationPageTemplate;
  [Pages.Allow]: AllowPageTemplate;
  [Pages.UseResetPasswordCode]: UseResetPasswordCodePageTemplate;
  [Pages.SendResetPasswordCode]: SendResetPasswordCodePageTemplate;
}

export interface MustacheCompiler {
  render(source: string, data: unknown): string;
}

export type Listeners = {
  type: string;
  element: Element;
  listener: EventListener;
}[];

export interface FrameAction {
  id: string;
  element: Element;
}

export interface FrameActionCallback {
  (element: Element): void;
}

export interface FrameActionsMap {
  [id: string]: FrameActionCallback | undefined;
}

export interface FrameActions {
  create(callback: FrameActionCallback): string;
  call(action: FrameAction): void;
  clear(): void;
}

export type Subscriber<T> = (state: T) => void;

export interface Publisher<T> {
  subscribe(subscriber: Subscriber<T>): void;
  unsubscribe(subscriber: Subscriber<T>): void;
}

export interface SessionData {
  app: StartResponse;
  XCSRFToken: string;
  lang: string;
  client: AuthorizationData;
  clientId: string | null;
}
