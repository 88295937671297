export default {
  login: 'Log in',
  incorrectIdentifierOrPassword: 'Incorrect identifier or password',
  emailPhoneNumber: 'Email, phone number...',
  email: 'Email',
  password: 'Password',
  enterYourEmail: 'Enter your email',
  enterYourPassword: 'Enter your password',
  enterYourEmailPhoneNumber: 'Enter your email, phone number...',
  next: 'Next',
  resetPassword: 'Reset password',
  resetLinkSending: 'Reset link sending',
  newPasswordCreation: 'New password creation',
  newPasswordCreated: 'New password created',
  expiredLink: 'Expired link',
  authorization: 'Authorization',
  communications: 'Communications',
  identifiers: 'Identifiers',
  names: 'Names',
  confirm: 'Confirm',
  error: 'Error',
  logout: 'Log out',
  or: 'or',
  changeAccount: 'Change account',
  newPasswordSetSuccessfully: 'New password set successfully',
  sendPasswordResetLink: 'Send password reset link',
  weSentPasswordResetInformationTo: "We've sent a password reset information to {0}",
  return: 'Return',
  passwordMustBeAtLeastCharactersInLength: 'Password must be at least {0} characters in length',
  reset: 'Reset',
  rememberMe: 'Remember me',
  yourPasswordResetLinkHasExpired: 'Your password reset link has expired',
  registration: 'Registration',
  atLeastOneWithTypeIsRequired: 'At least one {0} with type "{1}" is required',
  communication: 'communication',
  identifier: 'identifier',
  name: 'name',
  selectIdentifier: 'Select the identifier to send a code',
  code: 'Code',
  enterCode: 'Enter your code',
  sendingNewCodeIsPossibleAfter: 'Sending a new code is possible after {0}',
  send: 'send',
  incorrectCode: 'Incorrect code',
  tooManyAttemptsCodeMustBeResent: 'Too many attempts, code must be resent',
  emailOrPhone: 'Email or phone number (7xxxxxxxxxx)',
  enterEmailOrPhone: 'Enter your email or phone number (7xxxxxxxxxx)',
  twoFactorAuthentication: 'Two-factor authentication',
  autologin: 'Autologin',
  youInstructToSendInformationTo:
    'You instruct to send the following information to "{0}"',
  loginWith: 'Login with ESIA',
  pageNotFound: 'Page not found',
  loading: 'Loading',
  passwordMismatch: 'Passwords don\'t match',
} as const;
