/* eslint-disable import/prefer-default-export */
import Vue from 'vue';
import Vuex from 'vuex';
import {
  AuthorizationData, StoreState, SavedPages,
} from '@/types/index';

Vue.use(Vuex);

export const vueStore = new Vuex.Store<StoreState>({
  state: {
    clientId: undefined,
    clientLang: undefined,
    settings: null,
    lang: 'en',
    resetPasswordCode: '',
    savedPages: {},
    templates: {},
  },
  mutations: {
    authorize(state, payload: AuthorizationData) {
      state.settings = payload;
      state.lang = payload.lang;
    },
    setResetPasswordCode(state, payload: string) {
      state.resetPasswordCode = payload;
    },
    setLang(state, payload: string) {
      state.clientLang = payload;
      state.lang = payload;
    },
    setClientId(state, payload: string) {
      state.clientId = payload;
    },
    savePage<K extends keyof SavedPages>(
      state: StoreState,
      payload: { page: K; data: SavedPages[K] },
    ) {
      const { page, data } = payload;
      state.savedPages[page] = data;
    },
    saveTemplate(
      state: StoreState,
      payload: { type: string; data?: string },
    ) {
      const { type, data } = payload;
      state.templates[type] = data;
    },
  },
  actions: {},
  modules: {},
});
